@import url("../../../../node_modules/simplebar/dist/simplebar.css");

.simplebar-scrollbar:before {
    background: var(--bg-light-4);
}

.simplebar-track.simplebar-horizontal {
    height: 8px;
}
.simplebar-track.simplebar-vertical {
    width: 8px;
}
